import './component.scss'
import $ from 'jquery';
import 'Images/orange-check.svg'
import 'Images/nuri-kero.svg'
import 'Images/paint-bucket.svg'
import 'Images/yellow-paint-bucket.svg'
import 'Images/modal-close.svg'

window.addEventListener('load', () => {
  history.pushState(null, '', location.href);
  if (document.querySelector('.survey-modal')) {
    function modalOpen() {
      document.cookie = 'answered=true; max-age=604800;';
      document.getElementById('modal-survey').classList.add('is-open');
    }
  
    function modalClose() {
      document.getElementById('modal-survey').classList.remove('is-open');
    }
  
    function isAnsweredCookieAbsent() {
      return !document.cookie.split('; ').some(cookie => cookie.trim() === 'answered=true');
    }
  
    function ga_datalayer_push(checkboxes) {
      const other_text = document.getElementById('other_text').value;
      // データをdataLayerにPush
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'nurikaeSurveySubmit',
        nurikaeSurveyResult: `${checkboxes[0].value} ${other_text}`,
        nurikaeSurveyOtherText: other_text,
        nurikaeSurveyCurrentUrl: window.location.href,
      });
    }

    function otherTextMustBePresent() {
      const other_context_checkbox = document.getElementById('other_context');
      const other_text = document.getElementById('other_text');

      return (other_context_checkbox.checked && other_text.value.length === 0)
    }

    const checkboxes = document.querySelectorAll('.survey_checkbox');
  
    // チェックボックスごとに状態変更を監視する
    checkboxes.forEach(function(checkbox) {
      checkbox.addEventListener('change', function () {
        checkboxes.forEach(function(otherCheckbox) {
          if (otherCheckbox !== checkbox) {
            otherCheckbox.checked = false;
            otherCheckbox.closest('li').classList.remove('checked-item');
          }
        });
  
        const listItem = checkbox.closest('li'); // チェックボックスの親 <li> を取得
  
        // チェックされている場合にクラスを追加、されていない場合は削除
        if (checkbox.checked) {
          document.getElementsByClassName('js-survey-submit')[0].classList.remove('gray');
          listItem.classList.add('checked-item');
        } else {
          document.getElementsByClassName('js-survey-submit')[0].classList.add('gray');
          listItem.classList.remove('checked-item');
        }
      });
    });
  
    document.getElementsByClassName('survey-modal__overlay').forEach((element) => element.addEventListener('click', () => {
      modalClose();
      return false;
    }));
  
    document.getElementsByClassName('survey-modal__close').forEach((element) => element.addEventListener('click', () => {
      modalClose();
      return false;
    }));
  
    document.querySelector(".survey-modal__container").onclick = function (event) {
      // モーダル内はクリックイベントを伝播させない
      event.stopPropagation();
    };
  
    // 離脱モーダルの表示処理
    $(window).on('mousemove', (event) => {
      const y = event.clientY;
      if (y <= 50 && !$('#modal-survey').is(':visible') && isAnsweredCookieAbsent()) {
        modalOpen();
      }
    });
  
    const other_context_checkbox = document.getElementById('other_context');
    other_context_checkbox.addEventListener('change', () => {
      if (other_context_checkbox.checked) {
        document.getElementById('other_text').style.display = 'block'; // 表示
        document.querySelectorAll('ul.form-checklist li').forEach(function(item) {
          if (item !== other_context_checkbox.parentNode.parentNode) {
            item.style.display = 'none';
          }
        })
      } else {
        document.getElementById('other_text').style.display = 'none'; // 非表示
        document.querySelectorAll('ul.form-checklist li').forEach(function(item) {
          if (item !== other_context_checkbox.parentNode.parentNode) {
            item.style.display = 'block';
          }
        })
      }
    });
  
    const textarea = document.getElementById('other_text');
    textarea.addEventListener('input', function () {
      const errorMessage = document.getElementById('error-message');
      const maxLength = 100;
      if (textarea.value.length > maxLength) {
        textarea.value = textarea.value.slice(0, maxLength);  // 文字数を100文字に制限
        errorMessage.style.display = 'inline';  // エラーメッセージを表示
      } else {
        errorMessage.style.display = 'none';  // エラーメッセージを非表示
      }
    });

    document.getElementsByClassName('js-survey-submit')[0].addEventListener('click', (e) => {
      e.preventDefault();
      const errorMessage = document.getElementById('must-check');
      const checkedCheckboxes = Array.from(checkboxes).filter(function(checkbox) {
        return checkbox.checked;  // チェックされている場合にtrue
      });
      if (otherTextMustBePresent()) {
        errorMessage.style.display = 'inline';
        return false;
      };
      if (checkedCheckboxes.length === 0) {
        errorMessage.style.display = 'inline';
        return false;
      };

      ga_datalayer_push(checkedCheckboxes);
      document.getElementsByClassName('is-form')[0].style.display = 'none';
      document.getElementsByClassName('is-thanks')[0].style.display = 'flex';
      return false;
    });

    // popstateイベントを監視してブラウザバックを検出
    window.addEventListener('popstate', function () {
      console.log(isAnsweredCookieAbsent())
      if (isAnsweredCookieAbsent()) {
        // ブラウザバックを防止してモーダルを表示
        modalOpen();

        // 戻る操作を無効にする
        history.pushState(null, '', location.href);  // 履歴に現在のURLを再度プッシュして、戻れないようにする
      } else {
        history.back();
      }
    });
  }
});
